export default {
  type: "category-equipments",
  name: null,
  relationshipNames: [
    "organization"

  ],
  organization: {
    type: "organizations",
    id: null,
  },

  allowedUsers: [],
};
