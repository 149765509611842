<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) && !hideOrganization
          "
        >
          <organization-selector
            :organization="categoryEquipment.organization.id"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId) => {
                categoryEquipment.organization.id = organizationId;
                onFormChanged();
              }
            "
            :disabled="!!categoryEquipment.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
        <base-input
          :label="`${$t('COMMON.NAME')} (*)`"
          :required="true"
          v-model="categoryEquipment.name"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.name" />
      </div>
    </div>

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          categoryEquipment.id
            ? $t("CATEGORY_EQUIPMENTS.EDIT_CATEGORY_EQUIPMENT")
            : $t("CATEGORY_EQUIPMENTS.ADD_CATEGORY_EQUIPMENT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";
import CafeteriaSelector from "@/components/CafeteriaSelector.vue";
import ServiceCenterSelector from "@/components/ServiceCenterSelector.vue";
import WarehouseSelector from "@/components/WarehouseSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    EstablishmentSelector,
    CafeteriaSelector,
    ServiceCenterSelector,
    WarehouseSelector,
  },

  mixins: [formMixin],

  props: [
    "categoryEquipmentData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideSubmit",
  ],

  data() {
    let categoryEquipmentData = { ...this.categoryEquipmentData };
    categoryEquipmentData = this.$fillUserOrganizationData(categoryEquipmentData);
    return {
      categoryEquipment: categoryEquipmentData,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let categoryEquipmentData = cloneDeep(this.categoryEquipment);
      categoryEquipmentData = this.$fillUserOrganizationData(categoryEquipmentData);
      this.$emit("categoryEquipmentSubmitted", categoryEquipmentData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    categoryEquipmentData(categoryEquipmentData) {
      if (categoryEquipmentData) {
        this.categoryEquipment = {
          ...this.categoryEquipment,
          ...cloneDeep(categoryEquipmentData),
        };
      }
    },
  },
};
</script>
