var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) && !_vm.hideOrganization
        )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.categoryEquipment.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.categoryEquipment.id},on:{"organizationChanged":(organizationId) => {
              _vm.categoryEquipment.organization.id = organizationId;
              _vm.onFormChanged();
            }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"required":true},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.categoryEquipment.name),callback:function ($$v) {_vm.$set(_vm.categoryEquipment, "name", $$v)},expression:"categoryEquipment.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1)]),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.categoryEquipment.id ? _vm.$t("CATEGORY_EQUIPMENTS.EDIT_CATEGORY_EQUIPMENT") : _vm.$t("CATEGORY_EQUIPMENTS.ADD_CATEGORY_EQUIPMENT"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }